<template>
    <div class="modal-card">
        <header class="modal-card-head">
            <p class="modal-card-title">{{ title }}</p>
            <button class="delete" aria-label="close" @click="$parent.close()"></button>
        </header>
        <section class="modal-card-body">
            <div class="content is-small">
                <p>
                    Plan een prijswijziging. De labelprijzen worden berekend volgens de regels met de meest recente begindatum.
                </p>
                <p>
                    De nieuwe prijzen kunnen aangepast worden met een vermenigvuldigingsfactor. Op deze manier kunnen bijvoorbeeld kortingen makkelijk doorberekend worden. Let wel op: de vermenigvuldigingsfactor wordt alleen toegepast op vaste bedragen, niet op percentages.
                </p>
            </div>
            <form id="logic-copy-form" @submit.prevent="submit">
                <b-field
                    label="Begindatum"
                    message="De labelprijzen worden berekend volgens de regels met de meest recente begindatum."
                >
                    <b-datetimepicker
                        v-model="validFrom"
                        icon="calendar-today"
                        locale="nl-NL"
                        editable
                        position="is-top-right"
                        ref="validFromPicker"
                        :append-to-body="true"
                    >
                        <template #right>
                            <b-button type="is-primary" @click="togglePicker('validFromPicker')">OK</b-button>
                        </template>
                    </b-datetimepicker>
                </b-field>
                <b-field
                    label="Prijzen aanpassen"
                    :message="{
                        'Bijvoorbeeld 1 voor direct overnemen, 0,8 voor 20% korting of 1,025 voor 2,5% toeslag.': operator === 'multiply',
                        'Bijvoorbeeld 0 voor direct overnemen, -0,10 voor € 0,10 korting of 0,05 voor € 0,05 toeslag.':  operator === 'add'
                    }"
                >
                    <p class="control">
                        <b-select v-model="operator">
                            <option value="multiply">×</option>
                            <option value="add">+</option>
                        </b-select>
                    </p>
                    <b-input
                        v-model="factor"
                        type="number"
                        :min="operator === 'multiply' ? 0 : false"
                        :step="operator === 'multiply' ? 0.001 : 0.01"
                        expanded
                    ></b-input>
                </b-field>
            </form>
        </section>
        <footer class="modal-card-foot buttons is-right">
            <button class="button" type="button" @click="$parent.close()">
                {{ cancelText }}
            </button>
            <button class="button is-primary" type="submit" form="logic-copy-form" :class="{'is-loading': isSubmitting}" :disabled="isSubmitting">
                {{ confirmText }}
            </button>
        </footer>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import labelPriceMixin from '@/mixins/labelPrice';

export default {
    mixins: [labelPriceMixin],
    props: {
        title: String,
        confirmText: String,
        submitAction: Function,
        sourceId: String,
        cancelText: {
            type: String,
            default: 'Annuleren',
        },
        value: {
            type: Object,
            default: () => {},
        },
    },
    data () {
        return {
            validFrom: null,
            factor: 1,
            isSubmitting: false,
            operator: 'multiply'
        }
    },
    computed: {
        ...mapState({
            prices: state => state.labelPrice.all,
            isLoading: state => state.labelPrice.isLoading,
        }),
    },
    methods: {
        async submit() {
            this.isSubmitting = true;
            const source = this.prices.find(o => o.id == this.sourceId);
            let newId = null;
            try {
                newId = await this.createPrice({
                    courier_id: source.courier_id,
                    organisation_id: source.organisation_id,
                    plan_id: source.plan_id,
                    valid_from: this.validFrom,
                });
            } catch (e) {
                this.$buefy.dialog.alert({
                    title: 'Er ging iets mis!',
                    message: `Er is een fout opgetreden tijdens het aanpassen van prijsregels. Technische informatie: ${e}`,
                    type: 'is-danger',
                    hasIcon: true,
                });
            }
            if (!newId) {
                this.isSubmitting = false;
                return;
            }
            const prices = this.copyRules(this.sourceId, this.operator, this.factor);

            try {
                await this.$store.dispatch('labelPrice/updatePrice', {
                    id: newId,
                    updates: {prices: prices},
                });   
            } catch (e) {
                if (e.response && e.response.type === 'validation_error') {
                    this.$buefy.dialog.alert({
                        title: 'Fout tijdens kopiëren',
                        message: 'De prijsregels konden niet worden aangepast, omdat er een fout is gevonden in de bronregels.',
                        type: 'is-danger',
                        hasIcon: true,
                    });
                } else {
                    this.$buefy.dialog.alert({
                        title: 'Er ging iets mis!',
                        message: `Er is een fout opgetreden tijdens het aanpassen van prijsregels. Technische informatie: ${e}`,
                        type: 'is-danger',
                        hasIcon: true,
                    });
                }
            } finally {
                this.isSubmitting = false;
                this.$parent.close();
                this.$router.push({ name: 'price-single', params: {priceId: newId} });
            }
        },
        togglePicker(ref) {
            this.$refs[ref].toggle();
        },
    },
    created () {
        this.$store.dispatch('labelPrice/ensureData');
    },
    watch: {
        operator(newVal, oldVal) {
            if (oldVal === 'multiply' && this.factor == 1 && newVal === 'add')
                this.factor = 0;
            if (oldVal === 'add' && this.factor == 0 && newVal === 'multiply')
                this.factor = 1;
        },
    },
}
</script>
