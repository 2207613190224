<template>
<div class="block">
    <table class="table is-fullwidth is-hoverable is-striped">
        <thead>
            <tr>
                <th></th>
                <th>Naam</th>
                <th>Type</th>
                <th class="has-text-right">Prijs</th>
                <th class="has-text-right">BTW</th>
                <th></th>
            </tr>
        </thead>
        <draggable v-model="rules" tag="tbody" :options="draggableOptions" @sort="submitOrder">
            <tr v-for="rule in rules" :key="rule.id">
                <td class="is-vcentered">
                    <span class="sortable-handle icon is-small">
                        <i class="gg-arrow-align-v" aria-hidden="true"></i>
                        <span class="is-sr-only">Prijsregel sorteren</span>
                    </span>     
                </td>
                <td class="is-vcentered">
                    {{ rule.name }}
                </td>
                <td class="is-vcentered">
                    <template v-if="rule.type === 'fixed'">
                        Vaste prijs
                    </template>
                    <template v-else-if="rule.type === 'percentage'">
                        Percentage
                    </template>
                    <template v-else>
                        Onbekend
                    </template>
                </td>
                <td class="is-vcentered has-text-right">
                    <template v-if="rule.type === 'percentage'">
                        {{ rule.price.percentage / 100|formatNumber({style: 'percent', minimumFractionDigits: 2}) }}
                    </template>
                    <template v-else>
                        {{ rule.price|formatMoney }}
                    </template>
                </td>
                <td class="is-vcentered has-text-right">
                    {{ rule.vat }}%
                </td>
                <td class="is-vcentered buttons is-right">
                    <b-button size="is-small" @click="deleteRule(rule)">
                        <span class="icon is-small">
                            <i class="gg-trash" aria-hidden="true"></i>
                            <span class="is-sr-only">Prijsregel verwijderen</span>
                        </span>
                    </b-button>
                    <b-button size="is-small" @click="editRule(rule)">
                        <span class="icon is-small">
                            <i class="gg-pen" aria-hidden="true"></i>
                            <span class="is-sr-only">Prijsregel aanpassen</span>
                        </span>
                    </b-button>
                </td>
            </tr>
            <tr v-if="rules.length === 0">
                <td colspan="5" class="has-text-centered">
                    Er zijn momenteel geen prijsregels ingesteld
                </td>
            </tr>
        </draggable>
    </table>
    <b-button type="is-primary" @click="createRule">Prijsregel toevoegen</b-button>
    
</div>
</template>

<script>
import draggable from 'vuedraggable';
import LogicRuleFormModal from '@/components/LogicRuleFormModal';
export default {
    components: {draggable},
    props: {
        value: Array,
        path: Array,
        price: {
            type: Object,
            default: () => {},
        },
        courier: {
            type: Object,
            default: () => {},
        },
    },
    data () {
        return {
            rules: this.value,
            draggableOptions: {
                group: {
                    name: 'widget',
                    put: false,
                    pull: false,
                    clone: false
                },
                animation: 150,
                handle: '.sortable-handle',
            },
        };
    },
    methods: {
        createRule () {
            this.$buefy.modal.open({
                parent: this,
                component: LogicRuleFormModal,
                hasModalCard: true,
                trapFocus: true,
                canCancel: ['escape', 'outside'],
                props: {
                    courier: this.courier,
                    title: 'Nieuwe prijsregel',
                    confirmText: 'Prijsregel aanmaken',
                    destinationField: 'label_prices_rule',
                    submitAction: this.submitRule.bind(this),
                    validateName: this.validateName.bind(this),
                },
            });
        },
        editRule(rule) {
            this.$buefy.modal.open({
                parent: this,
                component: LogicRuleFormModal,
                hasModalCard: true,
                trapFocus: true,
                canCancel: ['escape', 'outside'],
                props: {
                    value: rule,
                    courier: this.courier,
                    title: 'Prijsregel aanpassen',
                    confirmText: 'Prijsregel aanpassen',
                    destinationField: 'label_prices_rule',
                    submitAction: this.submitRule.bind(this),
                },
            });
        },
        deleteRule(rule) {
            this.$buefy.dialog.confirm({
                title: 'Prijsregel verwijderen?',
                message: `Weet je zeker dat je deze prijsregel (${rule.name}) wilt verwijderen? Dit kan niet ongedaan gemaakt worden.`,
                cancelText: 'Annuleren',
                confirmText: 'Verwijderen',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: this.submitDelete.bind(this, rule),
            });
        },
        submitRule(updates) {
            return this.$store.dispatch('labelPrice/updatePrice', {
                id: this.price.id,
                updates: {
                    label_prices_path: this.path,
                    ...updates,
                }
            });
        },
        async submitDelete(rule) {
            try {
                await this.$store.dispatch('labelPrice/updatePrice', {
                    id: this.price.id,
                    updates: {
                        label_prices_path: this.path,
                        label_prices_delete: [rule._id],
                    }
                });
                this.$buefy.toast.open('Prijsregel verwijderd!');
            } catch (e) {
                let message = 'Fout opgetreden tijdens het verwijderen van een prijsregel.';
                if (e.response && e.response.type === 'validation_error')
                    message += ' Meer informatie over de fout: ' + e.response.data.shipment_logic_delete.join(' ');
                this.$buefy.dialog.alert({
                    title: 'Er ging iets mis!',
                    message: message,
                    type: 'is-danger',
                    hasIcon: true,
                });
            }

        },
        async submitOrder() {
            try {
                await this.$store.dispatch('labelPrice/updatePrice', {
                    id: this.price.id,
                    updates: {
                        label_prices_path: this.path,
                        label_prices_order: this.rules.map(o => o._id),
                    }
                });
            } catch (e) {
                let message = 'Fout opgetreden tijdens het updaten van de volgorde.';
                if (e.response && e.response.type === 'validation_error')
                    message += ' Meer informatie over de fout: ' + e.response.data.label_prices_order.join(' ');
                this.$buefy.dialog.alert({
                    title: 'Er ging iets mis!',
                    message: message,
                    type: 'is-danger',
                    hasIcon: true,
                });
            }
        },
        validateName (name) {
            return !this.rules?.some(o => o.name === name)
        },
    },
    watch: {
        value(value) {
            this.rules = value;
        },
    }
};
</script>
