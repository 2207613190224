<template>
    <div class="modal-card">
        <header class="modal-card-head">
            <p class="modal-card-title">{{ title }}</p>
            <button class="delete" aria-label="close" @click="$parent.close()"></button>
        </header>
        <section class="modal-card-body">
            <div class="content is-small">
                <p>
                    Door prijsregels te kopïeren kan je nieuwe prijsregels eenvoudig instellen, of bestaande prijsregels snel updaten. Het kopiëren van prijsregels overschrijft alle bestaande regels.
                </p>
                <p>
                    De nieuwe prijzen kunnen aangepast worden met een vermenigvuldigingsfactor. Op deze manier kunnen bijvoorbeeld kortingen makkelijk doorberekend worden. Let wel op: de vermenigvuldigingsfactor wordt alleen toegepast op vaste bedragen, niet op percentages.
                </p>
            </div>
            <form id="logic-copy-form" @submit.prevent="submit">
                <b-field label="Bronprijzen">
                    <b-select v-model="sourceId" placeholder="Selecteer een bron" expanded required>
                        <option v-for="price in prices" :value="price.id" :key="price.id">{{ formatPrice(price) }}</option>
                    </b-select>
                </b-field>
                <b-field
                    label="Prijzen aanpassen"
                    :message="{
                        'Bijvoorbeeld 1 voor direct overnemen, 0,8 voor 20% korting of 1,025 voor 2,5% toeslag.': operator === 'multiply',
                        'Bijvoorbeeld 0 voor direct overnemen, -0,10 voor € 0,10 korting of 0,05 voor € 0,05 toeslag.':  operator === 'add'
                    }"
                >
                    <p class="control">
                        <b-select v-model="operator">
                            <option value="multiply">×</option>
                            <option value="add">+</option>
                        </b-select>
                    </p>
                    <b-input
                        v-model="factor"
                        type="number"
                        :min="operator === 'multiply' ? 0 : false"
                        :step="operator === 'multiply' ? 0.001 : 0.01"
                        expanded
                    ></b-input>
                </b-field>
            </form>
        </section>
        <footer class="modal-card-foot buttons is-right">
            <button class="button" type="button" @click="$parent.close()">
                {{ cancelText }}
            </button>
            <button class="button is-primary" type="submit" form="logic-copy-form" :class="{'is-loading': isSubmitting}" :disabled="isSubmitting">
                {{ confirmText }}
            </button>
        </footer>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { getMoment } from '@/utils/functions';
import labelPriceMixin from '@/mixins/labelPrice';

export default {
    mixins: [labelPriceMixin],
    props: {
        title: String,
        confirmText: String,
        submitAction: Function,
        cancelText: {
            type: String,
            default: 'Annuleren',
        },
        value: {
            type: Object,
            default: () => {},
        },
    },
    data () {
        return {
            sourceId: null,
            factor: 1,
            isSubmitting: false,
            operator: 'multiply'
        }
    },
    computed: {
        ...mapState({
            couriers: state => state.courier.all,
            organisations: state => state.organisation.all,
            prices: state => state.labelPrice.all.sort(
                (a, b) => a.courier_id.localeCompare(b.courier_id)
                    || a.plan_id.localeCompare(b.plan_id)
                    || String(a.organisation_id).localeCompare(String(b.organisation_id))
            ),
            plans: state => state.plan.all,
            isLoading: state => state.courier.isLoading || state.labelPrice.isLoading,
        }),
    },
    methods: {
        formatPrice(price) {
            const plan = this.getPlan(price.plan_id);
            const courier = this.getCourier(price.courier_id);
            const organisation = this.getOrganisation(price.organisation_id);
            let output =  `${courier?.service_name} – ${plan?.name}`;
            if (organisation)
                output += ` (${organisation?.name})`;
            if (price.valid_from)
                output += ` – vanaf ${getMoment(price.valid_from).format('YYYY-MM-DD HH:mm')}`;
            return output;
        },
        getCourier(courierId) {
            return this.couriers.find(o => o.id == courierId);
        },
        getPlan(planId) {
            return this.plans.find(o => o.id == planId);
        },
        getOrganisation (organisationId) {
            return this.organisations.find(o => o.id == organisationId);
        },
        async loadOrganisations() {
            // async so this call can run in the background
            if (this.prices) {
                const ids = [...new Set(this.prices
                    .filter(o => o.organisation_id && !this.getOrganisation(o.organisation_id))
                    .map(o => o.organisation_id)
                )];
                if (ids.length > 0)
                    await this.$store.dispatch('organisation/loadView', {filter: {ids}});
            }
        },
        async loadCouriers() {
            // async so this call can run in the background
            if (this.prices) {
                const ids = [...new Set(this.prices
                    .filter(o => o.courier_id && !this.getCourier(o.courier_id))
                    .map(o => o.courier_id)
                )];
                if (ids.length > 0)
                    await this.$store.dispatch('courier/getCouriers', {ids});
            }
        },
        submit() {
            const destination = this.copyRules(this.sourceId, this.operator, this.factor);
            this.submitAction(destination);
            this.$parent.close();
        }
    },
    created () {
        this.$store.dispatch('labelPrice/ensureData');
        this.$store.dispatch('plan/ensureData');
    },
    watch: {
        prices: {
            handler () {
                // TODO: Make analogous to invoicelist once prices is paginated
                this.loadOrganisations();
                this.loadCouriers();
            },
            immediate: true,
        },
        operator(newVal, oldVal) {
            if (oldVal === 'multiply' && this.factor == 1 && newVal === 'add')
                this.factor = 0;
            if (oldVal === 'add' && this.factor == 0 && newVal === 'multiply')
                this.factor = 1;
        },
    },
}
</script>
