<template>
    <div class="modal-card">
        <header class="modal-card-head">
            <p class="modal-card-title">{{ title }}</p>
            <button class="delete" aria-label="close" @click="$parent.close()"></button>
        </header>
        <section class="modal-card-body">
            <form id="logic-condition-form" @submit.prevent="submit">
                <b-field
                    :type="{'is-danger': errors.name.length > 0}"
                    :message="errors.name.join(', ')"
                >
                    <b-input v-model="fields.name" required></b-input>
                    <template #label>
                        Naam
                        <b-tooltip type="is-dark" multilined position="is-right">
                            <span class="icon has-text-blue-light">
                                <i class="gg-info" aria-hidden="true"></i>
                            </span>
                            <template v-slot:content>
                                <p>De naam van de prijsregel is ook de naam van de factuurregel.</p>
                            </template>
                        </b-tooltip>
                    </template>
                </b-field>
                <b-field
                    label="Type"
                    :type="{'is-danger': errors.type.length > 0}"
                    :message="errors.type.join(', ')"
                >
                    <b-select v-model="fields.type" placeholder="Type…" expanded>
                        <option value="fixed">Vast bedrag</option>
                        <option value="percentage">Percentage</option>
                    </b-select>
                </b-field>
                <template v-if="fields.type === 'fixed'">
                    <b-field
                        label="Prijs"
                        :type="{'is-danger': errors.price.length > 0}"
                        :message="errors.price.join(', ')"
                    >
                        <p class="control">
                            <span class="button is-static">€</span>
                        </p>
                        <b-input v-model.number="fields.price" type="number" min="0" step="0.01" required expanded></b-input>
                    </b-field>
                </template>
                <template v-else-if="fields.type === 'percentage'">
                    <b-field
                        label="Percentage"
                        :type="{'is-danger': errors.price.percentage.length > 0}"
                        :message="errors.price.percentage.join(', ')"
                    >
                        <b-input v-model.number="fields.price.percentage" type="number" min="0" step="0.01"  required expanded></b-input>
                        <p class="control">
                            <span class="button is-static">%</span>
                        </p>
                    </b-field>
                    <b-field 
                        label="Veld"
                        :type="{'is-danger': errors.price.field.length > 0}"
                        :message="errors.price.field.join(', ')"
                        expanded
                    >
                        <b-select v-model="fields.price.field" expanded required>
                            <option
                                v-if="fields.price.field && !courierConditionFields[fields.price.field]"
                                :value="fields.price.field"
                                disabled
                            >
                                {{ fields.price.field.replace(/^(shipment\.courier_fields\.)/, '') }} (niet beschikbaar)
                            </option>
                            <option
                                v-for="(c, field) in courierConditionFields"
                                :value="field"
                                :key="field">
                                {{ c.fieldName }}
                            </option>
                        </b-select>
                    </b-field>
                </template>
                <b-field
                    label="BTW-percentage"
                    :type="{'is-danger': errors.vat.length > 0}"
                    :message="errors.vat.join(', ')"
                >
                    <b-input v-model.number="fields.vat" type="number" min="0" step="0.01" required expanded></b-input>
                    <p class="control">
                        <span class="button is-static">%</span>
                    </p>
                </b-field>
                <fieldset class="field">
                    <legend class="label">
                        <template>
                            Voorwaarden
                        </template>
                    </legend>
                    <table class="table is-fullwidth">
                        <thead>
                            <tr>
                                <th>Veld</th>
                                <th>Operator</th>
                                <th>Waarde</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(condition, idx) in fields.conditions" :key="idx">
                                <td>
                                    <b-field 
                                        label="Veld"
                                        custom-class="is-sr-only"
                                        :type="{'is-danger': errors.conditions[idx].field.length > 0}"
                                        :message="errors.conditions[idx].field.join(', ')"
                                        expanded
                                    >
                                        <b-select v-model="condition.field" expanded required>
                                            <option
                                                v-if="condition.field && !conditionFields[condition.field]"
                                                :value="condition.field"
                                                disabled
                                            >
                                                {{ condition.field.replace(/^(shipment\.courier_fields\.)/, '') }} (niet beschikbaar)
                                            </option>
                                            <option
                                                v-for="(c, field) in conditionFields"
                                                :value="field"
                                                :key="field">
                                                {{ c.fieldName }}
                                            </option>
                                        </b-select>
                                    </b-field>
                                </td>
                                <template v-if="conditionFields[condition.field]">
                                    <td>
                                        <b-field 
                                            label="Operator"
                                            custom-class="is-sr-only"
                                            :type="{'is-danger': errors.conditions[idx].operator.length > 0}"
                                            :message="errors.conditions[idx].operator.join(', ')"
                                            expanded
                                        >
                                            <b-select v-if="condition.field" v-model="condition.operator" expanded required>
                                                <option
                                                    v-for="o in conditionFields[condition.field].operators"
                                                    :value="o"
                                                    :key="o">
                                                    {{ operators[o] }}
                                                </option>
                                            </b-select>
                                            <b-select v-else expanded disabled required></b-select>
                                        </b-field>
                                    </td>
                                    <td>
                                        <b-field 
                                            label="Waarde"
                                            custom-class="is-sr-only"
                                            :type="{'is-danger': errors.conditions[idx].value.length > 0}"
                                            :message="errors.conditions[idx].value.join(', ')"
                                            expanded
                                        >
                                            <template v-if="!condition.operator || condition.operator === 'isset'">
                                                <b-input disabled></b-input>
                                            </template>
                                            <template v-else>
                                                <b-input
                                                    v-if="condition.field && conditionFields[condition.field].type === 'generic'"
                                                    v-bind="(conditionFields[condition.field].valueComponentAttrs || {})"
                                                    v-model="condition.value"
                                                    required
                                                ></b-input>
                                                <component 
                                                    v-else-if="condition.field && conditionFields[condition.field].type === 'custom'"
                                                    :is="conditionFields[condition.field].valueComponent"
                                                    v-model="condition.value"
                                                    v-bind="(conditionFields[condition.field].valueComponentAttrs || {})"
                                                    required
                                                ></component>
                                                <b-input v-else disabled required></b-input>
                                            </template>
                                        </b-field>
                                    </td>
                                </template>
                                <template v-else>
                                    <td>
                                        <b-field 
                                            label="Operator"
                                            custom-class="is-sr-only"
                                            :type="{'is-danger': errors.conditions[idx].operator.length > 0}"
                                            :message="errors.conditions[idx].operator.join(', ')"
                                            expanded
                                        >
                                            <b-select expanded disabled required :value="condition.operator">
                                                <option disabled :value="condition.operator">{{ operators[condition.operator] }}</option>
                                            </b-select>
                                        </b-field>
                                    </td>
                                    <td>
                                        <b-field 
                                            label="Waarde"
                                            custom-class="is-sr-only"
                                            :type="{'is-danger': errors.conditions[idx].value.length > 0}"
                                            :message="errors.conditions[idx].value.join(', ')"
                                            expanded
                                        >
                                            <b-input disabled required :value="condition.value"></b-input>
                                        </b-field>
                                    </td>
                                </template>
                                <td>
                                    <b-button @click="deleteCondition(idx)">
                                        <span class="icon">
                                            <i class="gg-trash" aria-hidden="true"></i>
                                            <span class="is-sr-only">Voorwaarde verwijderen</span>
                                        </span>
                                    </b-button>
                                </td>
                            </tr>
                            <tr v-show="fields.conditions.length === 0">
                                <td colspan="3">
                                    Geen voorwaarden. Dit betekent dat een order altijd deze vervoerder matcht.
                                </td>
                            </tr>
                            <tr>
                                <td colspan="3">
                                    <b-button icon-left="math-plus" @click="createCondition">
                                        Voorwaarde toevoegen
                                    </b-button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </fieldset>
                 <fieldset class="field">
                    <b-field
                        :type="{'is-danger': errors.operator.length > 0}"
                        :message="[
                            fields.operator === 'or' ?  'Pas deze prijsregel toe als een label aan minstens één voorwaarde voldoet.' : 'Pas deze prijsregel toe als een label aan alle voorwaarden voldoet.',
                            errors.operator.join(', '),
                        ]"
                    >
                        <b-radio-button v-model="fields.operator"
                            native-value="or"
                        >
                            <b-icon icon="close"></b-icon>
                            <span>Minstens één</span>
                        </b-radio-button>

                        <b-radio-button v-model="fields.operator"
                            native-value="and">
                            <b-icon icon="check"></b-icon>
                            <span>Alle</span>
                        </b-radio-button>
                        <template #label>
                            Matchingstrategie
                            <b-tooltip type="is-dark" size="is-large" multilined position="is-right">
                                <span class="icon has-text-blue-light">
                                    <i class="gg-info" aria-hidden="true"></i>
                                </span>
                                <template v-slot:content>
                                    <p>De matchingstrategie bepaalt aan hoeveel voorwaarden een label moet voldoen om deze prijsregel erop toe te passen.</p>
                                </template>
                            </b-tooltip>
                        </template>
                    </b-field>       
                </fieldset>
            </form>
        </section>
        <footer class="modal-card-foot buttons is-right">
            <button class="button" type="button" @click="$parent.close()">
                {{ cancelText }}
            </button>
            <button class="button is-primary" type="submit" form="logic-condition-form" :class="{'is-loading': isSubmitting}" :disabled="isSubmitting">
                {{ confirmText }}
            </button>
        </footer>
    </div>
</template>

<script>
import CountryCodeAutocomplete from '@/components/CountryCodeAutocomplete';

const OPERATORS = {
    isset: 'heeft waarde',
    eq:  'is',
    neq: 'is niet',
    gt:  'groter dan',
    gte: 'groter dan of gelijk aan',
    lt: 'kleiner dan',
    lte: 'kleiner dan of gelijk aan',
};

const CONDITION_FIELDS =  {
    country: {
        fieldName: 'Land',
        operators: ['eq'],
        type: 'custom',
        valueComponent: CountryCodeAutocomplete,
    },
    shipment_weight: {
        fieldName: 'Gewicht',
        operators: ['isset', 'eq', 'neq', 'gt', 'gte', 'lt', 'lte'],
        type: 'generic',
        valueComponentAttrs: {
            type: 'number',
            min: 0,
            step: 1,
        }
    },
};

export default {
    props: {
        title: String,
        confirmText: String,
        validateName: {
            type: Function,
            default: () => true,
        },
        destinationField: String,
        submitAction: [String, Function],
        cancelText: {
            type: String,
            default: 'Annuleren',
        },
        value: {
            type: Object,
            default: () => {},
        },
        courier: {
            type: Object,
            default: () => {},
        },
    },
    data () {
        let fields = {
            name: null,
            price: 0,
            vat: 21,
            operator: 'and',
            type: 'fixed',
            conditions: [],
        };

        Object.assign(fields, this.value);

        let errors = {
            name: [],
            type: [],
            price: [],
            vat: [],
            operator: [],
            conditions: fields.conditions.map((field) =>  ({
                field: (field.errors && field.errors.field) || [],
                operator: (field.errors && field.errors.operator) || [],
                value: (field.errors && field.errors.value) || [],
            })),
        };

        return {
            isSubmitting: false,
            operators: {...OPERATORS},
            conditionFields: {...CONDITION_FIELDS},
            fields,
            errors,
        }
    },
    computed: {
        hasErrors() {
            for (let field in this.errors)
                if (field !== 'conditions' && this.errors[field].length > 0)
                    return true;

            for (let idx = 0; idx < this.errors.conditions.length; idx++)
                for (let field in this.errors.conditions[idx].errors)
                    if (this.errors.conditions[idx][field].length > 0)
                        return true;

            return false;
        },
        courierConditionFields() {
            let fields = {};
            const prefix = 'shipment.courier_fields';
            for (let field of (this.courier?.courier_fields || [])) {
                if (field.type === 'checkbox') {
                    fields[`${prefix}.${field.name}`] = {
                        fieldName: field.label,
                        operators: ['isset'],
                        type: 'generic', // No need to explicitly accomodate for checkbox field if only operator is isset.
                    };
                }
                else if (field.type === 'hidden') {
                    fields[`${prefix}.${field.name}`] = {
                        fieldName: field.name,
                        operators: ['eq'],
                        type: 'generic', 
                    };
                }
                else if (field.type === 'text') {
                    fields[`${prefix}.${field.name}`] = {
                        fieldName: field.name,
                        operators: ['isset', 'eq', 'neq', 'gt', 'gte', 'lt', 'lte'], // value can be variable, so support all just in case,
                        type: 'generic', 
                    };
                }
                else if (field.type === 'select') {
                    fields[`${prefix}.${field.name}`] = {
                        fieldName: field.name,
                        operators: ['isset', 'eq', 'neq', 'gt', 'gte', 'lt', 'lte'], // value can be variable, so support all just in case
                        type: 'generic', 
                    };
                }
            }
            return fields;
        }
    },
    methods: {
        createCondition () {
            this.fields.conditions.push({
                field: null,
                operator: null,
                value: null,
            });
            this.errors.conditions.push({
                field: [],
                operator: [],
                value: [],
            });
        },
        deleteCondition (idx) {
            this.fields.conditions.splice(idx, 1);
        },
        processErrors (errors) {
            if (Array.isArray(errors)) {
                const message = `Fout opgetreden tijdens het aanpassen van de prijsregel.
                    Meer informatie over de fout: ${errors.join(' ')}`;
                this.$buefy.dialog.alert({
                    title: 'Er ging iets mis!',
                    message: message,
                    type: 'is-danger',
                    hasIcon: true,
                });
            } else if (errors) {
                for (let field in errors)
                    if (field !== 'conditions' && field !== 'price')
                        this.errors[field] = this.errors[field].concat(errors[field]);

                if (errors.price && typeof errors.price === 'object') {
                    for (let field in errors.price)
                        this.errors.price[field] = this.errors.price[field].concat(errors.price[field]);
                } else if (errors.price) {
                    this.errors.price = this.errors.price.concat(errors.price);
                }

                if (errors.conditions) {
                    for (let id in errors.conditions) {
                        for (let field in errors.conditions[id]) {
                            this.errors.conditions[id][field] = this.errors.conditions[id][field].concat(errors.conditions[id][field]);
                        }
                    }
                }
            }
        },
        validate () {
            let success = true;

            this.errors = {
                name: [],
                price: [],
                vat: [],
                type: [],
                operator: [],
                conditions: [],
            };

            if (this.fields.type === 'percentage')
                this.errors.price = {
                        field: [],
                        percentage: [],                    
                };

            for (let idx = 0; idx < this.fields.conditions.length; idx++)
                this.errors.conditions.push({
                    field: [],
                    operator: [],
                    value: [],
                });

            if (!this.validateName(this.fields.name)) {
                success = success && false;
                this.errors.name.push('Naam moet uniek zijn');
            }

            return success;
        },
        async submit () {
            if (!this.validate())
                return;

            let data = {};
            if (this.destinationField)
                data[this.destinationField] = this.fields;
            else
                data = this.fields;
            this.isSubmitting = true;
            try {
                if (this.submitAction instanceof Function) {
                    const r = this.submitAction(data);
                    if (r instanceof Promise)
                        await r;
                } else {
                    await this.$store.dispatch(this.submitAction, data);
                }
                this.$parent.close();
            } catch (e) {
                if (e.response && e.response.type === 'validation_error') {
                    let errors = e.response.data;
                    if (this.destinationField)
                        errors = e.response.data[this.destinationField];
                    this.processErrors(errors);
                } else {
                    console.error(e);
                }
            } finally {
                this.isSubmitting = false;
            }
        },
    },
    watch: {
        courier: {
            handler() {
                this.conditionFields = {...CONDITION_FIELDS, ...this.courierConditionFields};
            },
            immediate: true,
        },
        'fields.type': {
            handler(type) {
                if (type === 'percentage') {
                    this.fields.price = {
                        field: this.fields.price?.field || null,
                        percentage: this.fields.price?.percentage || null,
                    };
                    this.errors.price = {
                        field: [],
                        percentage: [],
                    }
                } else if (isNaN(this.fields.price)) {
                    this.fields.price = null;
                    this.errors.price = [];
                }
            },
            immediate: true,
        },  
    }
}
</script>
