<template>
    <div>
    <b-autocomplete
        v-model="model"
        :data="filteredCountries"
        field="name"
        :expanded="expanded"
        :size="size"
        :required="required"
        :append-to-body="true"
        @select="$emit('input', $event ? $event.code : null)"
        autocomplete="new-country"
    >
        <template slot="empty">Land niet gevonden</template>
    </b-autocomplete>
    </div>
</template>

<script>
import * as countries from 'i18n-iso-countries';
import { BCheckbox } from 'buefy/src/components/checkbox';

countries.registerLocale(require("i18n-iso-countries/langs/nl.json"));

export default {
    name: 'checkbox',
    extends: BCheckbox,
    props: {
        value: String,
        expanded: {
            type: Boolean,
            default: false,
        },
        size: String,
        required: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            model: this.sanitizeValue(this.value),
        }
    },
    computed: {
        countries () {
            return Object.entries(countries.getNames('nl')).map(
                country => ({code: country[0], name: country[1]})
            );
        },
        filteredCountries () {
            return this.countries.filter(
                country => country.name.toLowerCase().includes(this.model.toLowerCase())
            )
        }
    },
    methods: {
        sanitizeValue (value) {
            return countries.getName(value, 'nl') || ''; // Autocomplete breaks if this.model === undefined
        },
    },
    watch: {
        value (newVal) {
            this.model = this.sanitizeValue(newVal);
        },
    },
};
</script>
