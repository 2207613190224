<template>
<article class="section">
<div class="container">
    <header class="block">
        <div class="level">
            <nav class="level-left">
                <b-button @click="$router.backOrDefault(defaultReturnRoute)" icon-left="arrow-left" class="level-item">
                    Terug
                </b-button>
            </nav>
        </div>
        <h1 class="title">Prijsregels aanpassen</h1>
    </header>
    <b-loading v-model="isLoading"></b-loading>
    <section class="block" v-if="!isLoading">
            <table class="table is-narrow">
                <tbody>
                    <tr>
                        <th>Abonnement</th>
                        <td>
                            {{ plan.name }}
                        </td>
                    </tr>
                    <tr>
                        <th>Vervoerder</th>
                        <td>
                            {{ courier.service_name }}
                        </td>
                    </tr>
                    <tr v-if="price.organisation_id">
                        <th>Organisatie</th>
                        <td v-if="organisation">
                            {{ organisation.name }}
                        </td>
                    </tr>
                    <tr>
                        <th>Begindatum</th>
                        <td>
                            {{ price.valid_from|formatTimestamp }}
                        </td>
                    </tr>
                    <tr>
                        <th>Opmerkingen</th>
                        <td>
                            <form id="comments-form" @submit.prevent="submitComments">
                                <b-field
                                :type="{'is-danger': commentsErrors.length > 0}"
                                :message="commentsErrors.join(', ')"
                                >
                                    <b-input v-model="comments" maxlength="2049" type="textarea" :rows="2" aria-label="Opmerkingen" :has-counter="false"></b-input>
                                </b-field>
                                <div v-if="!isLoading && price.comments != comments" class="field is-grouped is-grouped-right">
                                    <p class="control">
                                        <b-button type="is-primary" native-type="submit" :loading="isSubmittingComments">Opslaan</b-button>
                                    </p>
                                </div>
                            </form>
                        </td>
                    </tr>
                    <tr>
                        <th class="is-vcentered">Acties</th>
                        <td class="is-vcentered">
                            <div class="buttons">
                                <b-button @click="scheduleLogic" expanded>
                                    Aanpassen vanaf datum
                                </b-button>
                                <b-button @click="copyLogic" expanded>
                                    Andere prijsregels kopiëren
                                </b-button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
    </section>

    <section class="block" v-if="!isLoading">
        <h2 class="title is-4">Prijsregels per verzendmethode</h2>
        <b-collapse
            class="card"
            animation="slide"
            v-for="shipmentType of courier.shipment_types"
            :key="shipmentType.id"
            :aria-id="`${shipmentType.id}-collapse`"
            :open="false"
        >
            <template #trigger="props">
                <div
                    class="card-header"
                    role="button"
                    :aria-controls="`${shipmentType.id}-collapse`"
                    :aria-expanded="props.open"
                >
                    <h3 class="card-header-title">
                        {{ shipmentType.name }}
                    </h3>
                    <a class="card-header-icon">
                        <b-icon
                            :icon="props.open ? 'chevron-down' : 'chevron-up'">
                        </b-icon>
                    </a>
                </div>
            </template>
            <div class="card-content">
                <h4 class="title is-6">Basisprijs</h4>
                <logic-editor
                    v-model="priceLogic[shipmentType.id].base_price"
                    :price="price"
                    :courier="courier"
                    :path="[shipmentType.id, 'base_price']"
                ></logic-editor>
                <h4 class="title is-6">Markup</h4>
                <logic-editor
                    v-model="priceLogic[shipmentType.id].markup"
                    :price="price"
                    :courier="courier"
                    :path="[shipmentType.id, 'markup']"
                ></logic-editor>
            </div>
        </b-collapse>
    </section>
</div>
</article>
</template>

<script>
import { mapState } from 'vuex';
import LogicEditor from '@/components/LogicEditor';
import LogicCopyModal from '@/components/LogicCopyModal';
import LogicScheduleModal from '@/components/LogicScheduleModal';

export default {
    components: {LogicEditor},
    data() {
        return {
            defaultReturnRoute: { name: 'price-list' },
            organisation: null,
            courier: null,
            priceLogic: {},
            isSubmitting: false,
            comments: null,
            commentsErrors: [],
            isSubmittingComments: false,
        }
    },
    computed: {
        ...mapState({
            plan(state) {
                if (!this.price)
                    return null;
                return state.plan.all.find(o => o.id === this.price.plan_id);
            },
            price(state) {
                return state.labelPrice.all.find(o => o.id === this.$route.params.priceId);
            },
        }),
        isLoading() {
            return this.$store.state.plan.isLoading
                || this.$store.state.labelPrice.isLoading
                || !this.courier
                || !this.price
                || this.isSubmitting;
        }
    },
    methods: {
        copyLogic() {
            this.$buefy.modal.open({
                parent: this,
                component: LogicCopyModal,
                hasModalCard: true,
                trapFocus: true,
                canCancel: ['escape', 'outside'],
                props: {
                    title: 'Prijsregels kopiëren',
                    confirmText: 'Prijsregels kopiëren',
                    destinationField: 'label_prices_rule',
                    submitAction: this.confirmCopyLogic.bind(this),
                },
            });
        },
        confirmCopyLogic(newLogic) {
            this.$buefy.dialog.confirm({
                title: 'Prijsregels kopiëren?',
                message: `Weet je zeker dat je deze prijsregels wilt kopiëren? Dit kan niet ongedaan gemaakt worden.`,
                cancelText: 'Annuleren',
                confirmText: 'Kopiëren',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: this.submitCopyLogic.bind(this, newLogic),
            });
        },
        async submitCopyLogic(newLogic) {
            this.isSubmitting = true;
            this.setPriceLogic(newLogic);
            try {
                await this.$store.dispatch('labelPrice/updatePrice', {
                    id: this.price.id,
                    updates: {prices: this.priceLogic},
                });   
            } catch (e) {
                if (e.response && e.response.type === 'validation_error') {
                    this.$buefy.dialog.alert({
                        title: 'Fout tijdens kopiëren',
                        message: 'De prijsregels konden niet worden gekopiëerd, omdat er een fout is gevonden in de bronregels.',
                        type: 'is-danger',
                        hasIcon: true,
                    });
                } else {
                    this.$buefy.dialog.alert({
                        title: 'Er ging iets mis!',
                        message: `Er is een fout opgetreden tijdens het kopiëren van prijsregels. Technische informatie: ${e}`,
                        type: 'is-danger',
                        hasIcon: true,
                    });
                }
            }
            this.isSubmitting = false;
        },
        scheduleLogic() {
            this.$buefy.modal.open({
                parent: this,
                component: LogicScheduleModal,
                hasModalCard: true,
                trapFocus: true,
                canCancel: ['escape', 'outside'],
                props: {
                    title: 'Aanpassing plannen',
                    confirmText: 'Aanpassing plannen',
                    destinationField: 'label_prices_rule',
                    sourceId: this.price.id,
                },
            });
        },
        initPriceLogic() {
            if (!this.courier || !this.price)
                return;
            this.setPriceLogic(this.price.prices);
        },
        setPriceLogic(source) {
            this.priceLogic = {};

            for (const shipmentType of this.courier.shipment_types) {
                if (source[shipmentType.id])
                    this.priceLogic[shipmentType.id] = source[shipmentType.id];
                else
                    this.priceLogic[shipmentType.id] = {
                        base_price: [],
                        markup: [],
                    };
            }
        },
        async loadOrganisation() {
            if (this.price?.organisation_id)
                this.organisation = await this.$store.dispatch('organisation/getObject', this.price?.organisation_id);
        },
        async loadCourier() {
            if (this.price?.courier_id)
                this.courier = await this.$store.dispatch('courier/getCourier', this.price?.courier_id);
        },
        async submitComments() {
            this.isSubmittingComments = true;
            this.commentsErrors = [];
            try {
                await this.$store.dispatch('labelPrice/updatePrice', {
                    id: this.price.id,
                    updates: {comments: this.comments},
                });
                this.$buefy.toast.open('Opmerkingen opgeslagen!');
            } catch (e) {
                if (e.response && e.response.type === 'validation_error') {
                    const errors = e.response.data;
                    if (errors.comments)
                        this.commentsErrors = errors.comments;
                }
            }
            this.isSubmittingComments = false;
        },
    },
    created() {
        this.$store.dispatch('plan/ensureData');
        this.$store.dispatch('labelPrice/ensureData');
    },
    watch: {
        price: {
            handler() {
                this.initPriceLogic();
                this.loadOrganisation();
                this.loadCourier();
                this.comments = this.price?.comments;
            },
            immediate: true,
        },
        courier: {
            handler() {
                this.initPriceLogic();
            },
            immediate: true,
        },
    },
};
</script>
