<template>
<article class="section">
<div class="container">
    <header class="block">
        <div class="level">
            <div class="level-left">
                <h1 class="title level-item">Labelprijzen</h1>
            </div>
            <div class="level-right">
                <b-button icon-left="math-plus" tag="router-link" :to="{ name: 'price-form'}" class="level-item">
                    Nieuwe prijsregels
                </b-button>
            </div>
        </div>
    </header>
    <section class="block">
        <b-table
            :data="prices"
            :striped="true"
            :hoverable="true"
            :loading="isLoading"
            :mobile-cards="false"
            default-sort="id"
        >
            <b-table-column field="id" :visible="false" sortable/><!-- Hack for default sort -->
            <b-table-column field="plan_id" label="Abonnement" cell-class="is-vcentered" v-slot="props">
                <v-with :plan="getPlan(props.row.plan_id)" v-slot="{plan}">
                    <template v-if="plan">
                        {{ plan.name }}
                    </template>
                    <b-skeleton v-else-if="props.row.plan_id" :animated="true"></b-skeleton>
                </v-with>
            </b-table-column>
            <b-table-column field="courier_id" label="Vervoerder" cell-class="is-vcentered" v-slot="props">
                <v-with :courier="getCourier(props.row.courier_id)" v-slot="{courier}">
                    <template v-if="courier">
                        {{ courier.service_name }}
                    </template>
                    <b-skeleton v-else-if="props.row.courier_id" :animated="true"></b-skeleton>
                </v-with>
            </b-table-column>
            <b-table-column field="organisation_id" label="Organisatie" cell-class="is-vcentered" v-slot="props">
                <v-with :organisation="getOrganisation(props.row.organisation_id)" v-slot="{organisation}">
                    <div>
                        <template v-if="organisation">{{ organisation.name }}</template>
                        <b-skeleton v-else-if="props.row.organisation_id" :animated="true"></b-skeleton>
                        <template v-else>–</template>
                    </div>
                </v-with>
            </b-table-column>
            <b-table-column field="valid_from" label="Geldig vanaf" cell-class="is-vcentered" v-slot="props">
                {{ props.row.valid_from|formatTimestamp }}
            </b-table-column>
            <b-table-column v-slot="props" cell-class="is-vcentered buttons is-right">
                <b-button
                    tag="router-link"
                    :to="{ name: 'price-single', params: {priceId: props.row.id} }"
                    size="is-small"
                >
                    <span class="icon is-small">
                        <i class="gg-pen" aria-hidden="true"></i>
                        <span class="is-sr-only">Prijzen aanpassen</span>
                    </span>
                </b-button>
                <b-dropdown position="is-bottom-left" append-to-body>
                    <button class="button is-small" slot="trigger">
                        <span class="icon is-small">
                            <i class="gg-more-vertical" aria-hidden="true"></i>
                            <span class="is-sr-only">Meer opties…</span>
                        </span>
                    </button>
                    <b-dropdown-item
                        aria-role="listitem"
                        @click="deactivatePrice(props.row)"
                    >
                        <b-icon icon="trash"></b-icon>
                        Verwijderen
                    </b-dropdown-item>
                </b-dropdown>
            </b-table-column>


            <template #empty>
                <div class="has-text-centered">Geen vervoerders gevonden</div>
            </template>
        </b-table>
    </section>
</div>
</article>
</template>

<script>
import { mapState } from 'vuex';

export default {
    computed: {
        ...mapState({
            couriers: state => state.courier.all,
            organisations: state => state.organisation.all,
            prices: state => state.labelPrice.all,
            plans: state => state.plan.all,
            isLoading: state => state.courier.isLoading || state.labelPrice.isLoading,
        }),
    },
    methods: {
        getCourier(courierId) {
            return this.couriers.find(o => o.id == courierId);
        },
        getPlan(planId) {
            return this.plans.find(o => o.id == planId);
        },
        getOrganisation (organisationId) {
            return this.organisations.find(o => o.id == organisationId);
        },
        async loadOrganisations() {
            // async so this call can run in the background
            if (this.prices) {
                const ids = [...new Set(this.prices
                    .filter(o => o.organisation_id && !this.getOrganisation(o.organisation_id))
                    .map(o => o.organisation_id)
                )];
                if (ids.length > 0)
                    await this.$store.dispatch('organisation/loadView', {filter: {ids}});
            }
        },
        async loadCouriers() {
            // async so this call can run in the background
            if (this.prices) {
                const ids = [...new Set(this.prices
                    .filter(o => o.courier_id && !this.getCourier(o.courier_id))
                    .map(o => o.courier_id)
                )];
                if (ids.length > 0)
                    await this.$store.dispatch('courier/getCouriers', {ids});
            }
        },
        deactivatePrice(price) {
            this.$buefy.dialog.confirm({
                title: 'Prijsregels verwijderen?',
                message: `Weet je zeker dat je deze prijsregels wilt verwijderen?`,
                cancelText: 'Annuleren',
                confirmText: 'Verwijderen',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: async () => {
                    await this.$store.dispatch('labelPrice/updatePrice', {
                        id: price.id,
                        updates: {is_active: false},
                    });
                    this.$buefy.toast.open('Prijsregels verwijderd!');
                },
            });
        },
    },
    created () {
        this.$store.dispatch('labelPrice/ensureData');
        this.$store.dispatch('plan/ensureData');
    },
    watch: {
        prices: {
            handler () {
                // TODO: Make analogous to invoicelist once prices is paginated
                this.loadOrganisations();
                this.loadCouriers();
            },
            immediate: true,
        }
    }
};
</script>
