<template>
<form-page>
    <header class="container block">
        <!-- I'm not too fond of this header, but it works for now... -->
        <nav class="block">
            <b-button @click="$router.backOrDefault(defaultReturnRoute)" icon-left="arrow-left">
                Terug
            </b-button>
        </nav>
        <h1 class="title">Nieuwe prijsregels</h1>
    </header>
    <form id="price-form" @submit.prevent="submit">
        <section class="container block">
            <b-field
                label="Organisatie (optioneel)"
                :type="{'is-danger': errors.organisation_id.length > 0}"
                :message="errors.organisation_id.join(', ')"
            >
                <b-autocomplete
                    v-model="fields.organisation_name"
                    :data="organisationAutocomplete"
                    field="name"
                    clearable
                    :loading="isLoadingOrganisationAutocomplete"
                    @typing="handleOrganisationAutocomplete"
                    @select="option => fields.organisation_id = option ? option.id : null"
                ></b-autocomplete>
            </b-field>
            <b-field
                label="Abonnement"
                :type="{'is-danger': errors.plan_id.length > 0}"
                :message="errors.plan_id.join(', ')"
            >
                <b-select v-model="fields.plan_id" placeholder="Selecteer een abonnement" expanded required>
                    <option v-for="plan in plans" :value="plan.id" :key="plan.id">{{ plan.name }}</option>
                </b-select>
            </b-field>
            <b-field
                label="Vervoerder"
                :type="{'is-danger': errors.courier_id.length > 0}"
                :message="errors.courier_id.join(', ')"
            >
                <b-select v-model="fields.courier_id" placeholder="Selecteer een vervoerder" expanded required :disabled="isLoading">
                    <option v-for="courier in couriers" :value="courier.id" :key="courier.id">{{ courier.service_name }}</option>
                </b-select>
            </b-field>
            <b-field
                label="Begindatum"
                :type="{'is-danger': errors.valid_from.length > 0}"
                :message="errors.valid_from.join(', ')"
            >
                <b-datetimepicker
                    v-model="fields.valid_from"
                    icon="calendar-today"
                    locale="nl-NL"
                    editable
                    position="is-top-right"
                    ref="validFromPicker"
                    :append-to-body="true"
                >
                    <template #right>
                        <b-button type="is-primary" @click="togglePicker('validFromPicker')">OK</b-button>
                    </template>
                </b-datetimepicker>
            </b-field>
            <b-field
                label="Opmerkingen (optioneel)"
                :type="{'is-danger': errors.comments.length > 0}"
                :message="errors.comments.join(', ')"
            >
                <b-input v-model="fields.comments" maxlength="2048" type="textarea"></b-input>
            </b-field>
        </section>
    </form>

    <template #footer>
        <div class="container buttons is-right">
            <b-button type="is-primary" native-type="submit" form="price-form" :loading="isSubmitting">
                Prijsregels aanmaken
            </b-button>
        </div>
    </template>
</form-page>
</template>

<script>
import { mapState } from 'vuex';
import { debounce } from '@/utils/functions';
import labelPriceMixin from '@/mixins/labelPrice';
import FormPage from '@/components/FormPage';

export default {
    mixins: [labelPriceMixin],
    components: {FormPage},
    data() {
        return {
            defaultReturnRoute: { name: 'price-list' },
            organisationAutocomplete: [],
            isSubmitting: false,
            isLoadingOrganisationAutocomplete: false,
            fields: {
                courier_id: null,
                organisation_name: null,
                organisation_id: null,
                plan_id: null,
                comments: null,
                valid_from: new Date(),
            },
            errors: {
                courier_id: [],
                organisation_name: [],
                organisation_id: [],
                plan_id: [],
                comments: [],
                valid_from: [],
            },
        };
    },
    computed: {
        ...mapState({
            couriers(state) {
                return state.courier.all.filter(
                    o => !o.organisation_id // Proxy implementation, available to every org (is_proxied = true in db)
                        || (
                            o.organisation_id == this.fields.organisation_id
                            && !o.is_proxy // avoid duplicates with the actual proxy (service_class = ProxyCourier, sorry, this is confusing)
                        )
                );
            },
            organisations: state => state.organisation.all,
            prices: state => state.labelPrice.all,
            plans: state => state.plan.all,
            isLoading: state => state.courier.isLoading || state.plan.isLoading || state.labelPrice.isLoading,
        }),
    },
    methods: {
        getCourier(courierId) {
            return this.couriers.find(o => o.id == courierId);
        },
        getPlan(planId) {
            return this.plans.find(o => o.id == planId);
        },
        getOrganisation(organisationId) {
            return this.organisations.find(o => o.id == organisationId);
        },
        handleOrganisationAutocomplete: debounce(async function (name) {
            if (!name.length) {
                this.organisationAutocomplete = [];
            } else {
                this.isLoadingOrganisationAutocomplete = true;
                const result = await this.$store.dispatch('organisation/loadView', {filter: {autocomplete: name}});
                this.organisationAutocomplete = result?.data || [];
                this.isLoadingOrganisationAutocomplete = false;
            }
        }, 300),
        async submit() {
            this.isSubmitting = true;
            try {
                this.createPrice(this.fields);
                // Use push for navigation, as action is not semantically equivalent to going back in history
                this.$router.push({ name: 'price-list'});
            } catch (e) {
                if (e.response && e.response.type === 'validation_error') {
                    let errors = e.response.data;

                    for (const field in errors) {
                        this.errors[field] = this.errors[field].concat(errors[field]);
                    }
                }
            } finally {
                this.isSubmitting = false;
            }
        },
        togglePicker(ref) {
            this.$refs[ref].toggle();
        },
    },
    created () {
        this.$store.dispatch('labelPrice/ensureData');
        this.$store.dispatch('plan/ensureData');
        this.$store.dispatch('courier/getCouriers', {is_proxy: true});
    },
    watch: {
        'fields.organisation_id'(value) {
            if (value) {
                const organisation = this.getOrganisation(value);
                if (organisation?.plan)
                    this.fields.plan_id = organisation?.plan.id;
                this.$store.dispatch('courier/getCouriers', {organisation_id: value});
            }
        },
    }
};
</script>
