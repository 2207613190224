import { mapState } from 'vuex';

export default {
    computed: {
        ...mapState({
            prices: state => state.labelPrice.all,
        }),
    },
    methods: {
        async createPrice(fields) {
            const existing = this.prices.find(
                o => ( o.courier_id == fields.courier_id 
                    && o.organisation_id == fields.organisation_id
                    && o.plan_id == fields.plan_id
                    && o.valid_from == fields.valid_from
                )
            );
            if (existing) {
                this.$buefy.dialog.alert({
                    title: 'Dubbele prijzen zijn niet toegestaan',
                    message: 'Er bestaan al prijzen voor deze combinatie van vervoerder, organisatie, abonnement en begindatum.',
                    type: 'is-danger',
                    hasIcon: true,
                });
                return;
            }
            return await this.$store.dispatch('labelPrice/createPrice', fields);
        },
        copyRules(sourceId, operator, factor) {
            const source = this.prices.find(o => o.id == sourceId)?.prices;
            let destination = {};
            for (const st in source) {
                destination[st] = {};
                for (const pt in source[st]) {
                    destination[st][pt] = [];
                    for (const rule of source[st][pt]) {
                        // copy and reset ID
                        let newRule = {...rule};
                        delete newRule._id;
                        // Copy conditions and reset IDs
                        newRule.conditions = [];
                        for (const condition of rule.conditions) {
                            let newCondition = {...condition};
                            delete newCondition._id;
                            newRule.conditions.push(newCondition);
                        }
                        // Calculate new price if fixed
                        if (rule.type === 'fixed' && operator === 'multiply') {
                            newRule.price = parseFloat(newRule.price) * parseFloat(factor);
                            newRule.price = parseFloat(newRule.price.toFixed(2));
                        } else if (rule.type === 'fixed' && operator === 'add') {
                            newRule.price = parseFloat(newRule.price) + parseFloat(factor);
                            newRule.price = parseFloat(newRule.price.toFixed(2));
                        }
                        destination[st][pt].push(newRule);
                    }
                }
            }
            return destination;
        }
    },
    created () {
        this.$store.dispatch('labelPrice/ensureData');
    },
};
